@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// support
// ================================================================
.support {
  margin-top: rem(118.93px);
  width: 100%;
  font-family: $font-family-YuMincho;
}
.support__content {
}
.support__icon {
  width: rem(81.34px);
  height: rem(83.07px);
  margin: 0 auto;
}
.support__text {
  @include txt(20px, 500, $color-default-font, center);
  @include txt-option(1, 0px);
  margin-top: rem(3px);
  font-family: $font-family-YuGothic;
}
.support__title {
  margin-top: rem(27px);
  @include txt(35px, 500, $color-default-font, center);
  @include txt-option(1, 0px);
  display: flex;
  align-items: center;
  justify-content: center;
  @include mq(sp-s) {
    display: block;
    font-size: 32px;
  }
}
.support__title-logo {
  width: rem(89px);
  margin-left: rem(5px);
  @include mq(sp-s) {
    display: block;
    margin: 0 auto;
    margin-top: rem(20px);
  }
}

// ================================================================
//  support-contents
// ================================================================
.support__wrapper {
  width: 100%;
  margin-top: rem(60px);
  @include mq(sp-tb) {
    margin-top: rem(25px);
  }
}
.support__items {
  display: block;
  @include mq(sp-tb) {
    display: flex;
    justify-content: space-between;
  }
}
.support__item {
  max-width: rem(600px);
  width: 100%;
  margin: 0 auto;
  background-color: $color-blue;
  position: relative;
  height: auto;
  @include mq(tb) {
    min-height: rem(800px);
  }
  @include mq(sp-tb) {
    min-height: rem(689px);
    max-width: rem(404px);
    width: 31%;
    margin: 0;
  }
}
.support__item + .support__item {
  margin-top: rem(40px);
  @include mq(sp-tb) {
    margin-top: auto;
  }
}
.support__item-title {
  width: 100%;
  height: rem(100px);
  background-color: $color-green;
  display: flex;
  align-items: center;
  justify-content: center;
  @include txt(35px, 500, $color-white, center);
  @include txt-option(1.17, 0px);
  @include mq(pc) {
    height: rem(140px);
    font-size: 45px;
  }
}
.support__item-text {
  margin: 0 rem(20px);
  margin-top: rem(30px);
  @include txt(16px, 500, $color-default-font, left);
  @include txt-option(1.93, 0px);
  font-family: $font-family-YuGothic;
  @include mq(pc) {
    margin: 0 rem(40px);
    margin-top: rem(30px);
  }
}
.support__item-img {
  width: 100%;
  height: auto;
  aspect-ratio: 404/274;
  margin-top: rem(30px);
  padding-bottom: rem(30px);
  @include mq(pc) {
    position: absolute;
    margin: 0;
    padding: 0;
    bottom: rem(63px);
  }
}
.support__item-img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
