@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// footer
// ================================================================
.footer {
  width: 100%;
  background-color: $color-blue;
  margin-top: rem(79px);
  padding-top: rem(50.5px);
  padding-bottom: rem(63px);
}
.footer__content {
  border-bottom: 0.5px solid $color-black;
  padding-bottom: rem(39.38px);
  position: relative;
}

// ================================================================
//  footer__nav
// ================================================================
.footer__nav {
  max-width: rem(400px);
  width: 100%;
  margin: 0 auto;
  @include mq(sp-tb) {
    max-width: rem(1062px);
  }
}
.footer__items {
  display: block;
  @include mq(sp-tb) {
    display: flex;
  }
}
.footer__item {
  max-width: rem(120px);
  width: 100%;
  margin-left: auto;
  @include mq(sp-tb) {
    max-width: rem(177px);
    margin: 0;
    text-align: center;
  }
}
.footer__item a {
  @include txt(16px, 500, $color-default-font, center);
  @include txt-option(1.687, 0px);
  font-family: $font-family-YuGothic;
  transition: all 0.3s ease-out;
}
@media (hover: hover) {
  .footer__item a:hover {
    color: $hover-hex;
    opacity: 1;
  }
}
@media (hover: none) {
  .footer__item a:active {
    color: $hover-hex;
    opacity: 1;
  }
}

// ================================================================
//  footer__wrapper
// ================================================================
.footer__wrapper {
  max-width: rem(400px);
  width: 100%;
  margin: 0 auto;
  margin-top: rem(19.5px);
  @include mq(sp-tb) {
    display: flex;
    max-width: rem(1056.46px);
    padding-left: rem(29.29px);
    padding-right: rem(29.29px);
  }
}
.footer__wrapper-img {
  max-width: rem(132.1px);
  width: 100%;
  max-height: rem(145.29px);
  height: 100%;
  position: absolute;
  top: 0;
  @include mq(sp-tb) {
    position: initial;
    margin-top: rem(9.71px);
  }
}
.footer__wrapper-img a {
  display: block;
  width: 100%;
  height: 100%;
}
.footer__contact {
  margin: 0 auto;
  margin-top: rem(50px);
  @include mq(sp-tb) {
    margin-top: rem(8.5px);
    margin-left: rem(20px);
    margin-right: rem(20px);
  }
  @include mq(pc-m) {
    margin-left: rem(41.35px);
  }
}
.footer__contact-text {
  @include txt(16px, 500, $color-default-font, Left);
  @include txt-option(1.562, 0px);
  font-family: $font-family-YuGothic;
}
.footer__contact-text p:nth-of-type(even) {
  padding-left: rem(16px);
}
.footer__contact-title {
  width: 100%;
  height: rem(29.32px);
  display: flex;
  align-items: center;
  padding-left: rem(8px);
  margin-top: rem(17px);
  background-color: $backcolor-green;
  @include txt(18px, 700, $color-white, Left);
  @include txt-option(1, 0px);
  font-family: $font-family-YuGothic;
  transition: all 0.3s ease-out;
  @include mq(sp-tb) {
    max-width: rem(289px);
  }
}
.footer__contact-title:nth-child(4) {
  margin-top: rem(27.68px);
}
.footer__contact-tel {
  margin-top: rem(4px);
}
.footer__contact-tel a {
  @include txt(35px, 700, $color-default-font, Left);
  @include txt-option(1, 0px);
  font-family: $font-family-YuGothic;
  @include mq(sp-s) {
    font-size: 28px;
  }
}
.footer__contact-tel span {
  @include txt(25px, 700, $color-default-font, Left);
  @include txt-option(1, 0px);
  font-family: $font-family-YuGothic;
  @include mq(sp-s) {
    font-size: 20px;
  }
}
.footer__wrapper-map {
  max-width: rem(308.73px);
  max-height: rem(189.38px);
  width: 100%;
  margin: 0 auto;
  margin-top: rem(50px);
  @include mq(sp-tb) {
    margin-top: rem(16px);
  }
  @include mq(pc-m) {
    margin-top: rem(16px);
    margin-right: rem(32.71px);
    margin-left: auto;
  }
}
@media (hover: hover) {
  .footer__contact-tel a:hover {
    @include mq(pc) {
      color: $color-default-font;
      opacity: 1;
      cursor: default;
    }
  }
  .footer__contact-title:nth-child(4):hover {
    opacity: 1;
    background-color: $hover-green;
  }
}
@media (hover: none) {
  .footer__contact-tel a:active {
    @include mq(pc) {
      color: $color-default-font;
      opacity: 1;
      cursor: default;
    }
  }
  .footer__contact-title:nth-child(4):active {
    opacity: 1;
    background-color: $hover-green;
  }
}

// ================================================================
//  スマホサイズ時のみ電話がかけられるようにする
// ================================================================
@include mq(pc) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

// ================================================================
//  footer__foot
// ================================================================
.footer__foot {
  margin-top: rem(15.5px);
  display: block;
  @include mq(sp-tb) {
    display: flex;
    justify-content: space-between;
  }
}
.footer__foot-link {
  max-width: rem(333px);
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @include mq(sp-tb) {
    margin: 0;
  }
}
.footer__foot-link a {
  display: block;
  @include txt(16px, 500, $color-default-font, center);
  @include txt-option(1.93, 0px);
  font-family: $font-family-YuGothic;
}
.footer__copy {
  @include txt(16px, 500, $color-default-font, center);
  @include txt-option(1.93, 0px);
  font-family: $font-family-YuGothic;
  margin-top: rem(20px);
  @include mq(sp-tb) {
    margin-top: 0;
  }
}
