@charset "UTF-8";
@use "../../global" as *;

// ================================================================
//  price__guid
// ================================================================
.price__guid {
  width: 100%;
  overflow: hidden;
  padding-top: rem(149px);
}
.price__guid__inner {
  width: 100%;
  height: 100%;
  max-width: rem(978px);
  padding: 0 rem(20px);
  margin: 0 auto;
  @include mq(pc) {
    padding: 0 rem(40px);
  }
}
.price__guid-content {
  margin-top: rem(118px);
}
.price__guid-title {
  @include txt(45px, 500, $color-default-font, center);
  @include txt-option(1.17, 0px);
  @include mq(sp-s) {
    font-size: 36px;
  }
}
.price__guid-campaign {
  width: 100%;
  height: rem(64px);
  margin-top: rem(99px);
  border-top: 1px solid $color-gray;
  border-bottom: 1px solid $color-gray;
}
.price__guid-campaign p {
  width: rem(326px);
  height: 100%;
  margin: 0 auto;
  background-color: $color-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  @include txt(28px, 700, $color-default-font, center);
  @include txt-option(1.68, 0px);
  font-family: $font-family-YuGothic;
  @include mq(sp-s) {
    font-size: 24px;
    width: rem(256px);
  }
  @include mq(pc) {
    width: rem(476px);
    font-size: 32px;
  }
}
.price__guid-text {
  margin-top: rem(8.5px);
  @include txt(24px, 700, $color-default-font, center);
  @include txt-option(1.7, 0px);
  font-family: $font-family-YuGothic;
  @include mq(sp-s) {
    padding: 0 rem(10px);
    font-size: 18px;
  }
}

// ================================================================
//  料金表
// ================================================================
.price__guid__items {
  max-width: rem(910px);
  width: 100%;
  margin-top: rem(55.5px);
  position: relative;
}
.price__guid__items-background {
  position: absolute;
  top: 0;
  left: -18px;
  width: rem(210px);
  height: 100%;
  background-color: $color-blue;
  z-index: -1;
  opacity: 0.18;
  @include mq(sp-s) {
    left: -10px;
    width: rem(160px);
  }
  @include mq(pc) {
    width: rem(240px);
  }
}
.price__guid__item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-gray;
}
.price__guid__item:nth-last-child(1) {
  border-bottom: none;
}
.price__guid__item-title {
  width: rem(182px);
  @include txt(20px, 700, $color-default-font, left);
  @include txt-option(1.7, 0px);
  font-family: $font-family-YuGothic;
  padding-top: rem(24.5px);
  padding-bottom: rem(24.5px);
  margin-left: rem(13px);
  margin-right: rem(20px);
  @include mq(sp-s) {
    width: rem(148px);
    font-size: 18px;
    margin-left: 0;
    margin-right: rem(10px);
  }
  @include mq(pc) {
    width: rem(192px);
    font-size: 24px;
    margin-right: rem(50px);
  }
}
.price__guid__item-time {
  width: rem(74px);
  height: rem(28px);
  display: flex;
  align-items: center;
  justify-content: center;
  @include txt(20px, 500, $color-white, center);
  @include txt-option(1, 0px);
  font-family: $font-family-YuGothic;
  background-color: $color-green;
  @include mq(sp-s) {
    font-size: 16px;
    width: rem(44px);
  }
  @include mq(pc) {
    width: rem(90px);
  }
}
.price__guid__item-price {
  margin-left: rem(6px);
  @include txt(28px, 500, $color-default-font, left);
  @include txt-option(1.51, 0px);
  @include mq(sp-s) {
    font-size: 20px;
  }
  @include mq(pc) {
    font-size: 35px;
  }
}
.price__guid__item-price span {
  font-size: 20px;
  @include mq(sp-s) {
    font-size: 16px;
  }
  @include mq(pc) {
    font-size: 25px;
  }
}
.price__guid__item:nth-of-type(1) {
  .price__guid__item-title {
    padding-top: rem(15.5px);
  }
  .price__guid__item-time {
    margin-bottom: rem(9px);
  }
  .price__guid__item-price {
    margin-bottom: rem(9px);
  }
}
.price__guid__item:nth-last-of-type(1) {
  .price__guid__item-title {
    padding-bottom: rem(40.5px);
  }
  .price__guid__item-time {
    margin-bottom: rem(16px);
  }
  .price__guid__item-price {
    margin-bottom: rem(16px);
  }
}
