@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// intro
// ================================================================
.intro {
  width: 100%;
  height: auto;
  margin-top: rem(76px);
  @include mq(pc) {
    margin-top: rem(153px);
  }
}
.intro__area {
  width: 100%;
  height: 100%;
  background: $backcolor-blue;
}
.intro__wrapper {
  width: inherit;
  height: inherit;
  padding-top: rem(53px);
  padding-bottom: rem(46px);

  padding-left: rem(20px);
  padding-right: rem(20px);
  @include mq(sp-m) {
    padding-left: rem(40px);
    padding-right: rem(40px);
  }
  @include mq(pc) {
    padding-top: rem(107px);
    padding-bottom: rem(93px);
    padding-left: rem(60px);
    padding-right: rem(60px);
  }
  @include mq(pc-m) {
    padding-left: toVw(188);
    padding-right: toVw(178);
  }
}

// ================================================================
//  intro__main
// ================================================================
.intro__main {
  display: block;
  @include mq(sp-tb) {
    display: flex;
    justify-content: space-between;
  }
}
.intro__main__content {
  width: 100%;
  max-width: rem(799px);
}
.intro__main__content-title {
  @include txt(35px, 500, $color-default-font, left);
  @include txt-option(1.514, 0px);
  @include mq(sp-s) {
    font-size: rem(28px);
  }
}
.intro__main__content-text {
  margin-top: rem(22px);
  @include txt(23px, 500, $color-default-font, left);
  @include txt-option(1.739, 0px);
  @include mq(sp-s) {
    font-size: rem(16px);
  }
}
.intro__main__content-name {
  margin-top: rem(46px);
  margin-left: auto;
  @include txt(23px, 500, $color-default-font, right);
  @include txt-option(1.739, 0px);
  @include mq(sp-s) {
    font-size: rem(16px);
  }
}
.intro__main__content-degree {
  margin-right: 10px;
  @include txt(16px, 500, $color-default-font, right);
  @include txt-option(1.687, 0px);
  @include mq(sp-s) {
    font-size: rem(14px);
  }
}
.intro__main-img {
  max-width: rem(362px);
  aspect-ratio: 362/443;
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-top: rem(80px);
  @include mq(sp-s) {
    max-width: rem(300px);
    aspect-ratio: 362/443;
  }
  @include mq(sp-tb) {
    max-width: rem(382px);
    aspect-ratio: 382/443;
    padding-left: rem(20px);
    margin-right: 0;
    margin-left: auto;
    margin-top: auto;
  }
}
.intro__main-img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

// ================================================================
//  intro
// ================================================================
.intro__history {
  margin-top: rem(75px);
}
.intro__degree {
  margin-top: rem(63px);
}
.intro__activity {
  margin-top: rem(86px);
}
.intro__title {
  width: 100%;
  height: rem(36px);
  background-color: $color-green;
  display: flex;
  align-items: center;
  @include txt(23px, 500, $color-white, left);
  @include txt-option(1.739, 0px);
  padding-left: rem(16px);
  @include mq(sp-s) {
    font-size: 20px;
  }
}
.intro__text {
  @include txt(23px, 500, $color-default-font, left);
  @include txt-option(1.739, 0px);
  margin-top: rem(22px);
  @include mq(sp-s) {
    font-size: 16px;
  }
}
.intro__activity-content {
  margin-top: rem(22px);
  width: 100%;
  @include txt(23px, 500, $color-default-font, left);
  @include txt-option(1.739, 0px);
  @include mq(sp-s) {
    font-size: 16px;
  }
}
.intro__activity-content__text {
  display: flex;
}
.intro__activity-content__text p:nth-of-type(1) {
  white-space: nowrap;
}
