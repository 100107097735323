@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// inner
// ================================================================
.inner {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: rem(1619px);
  padding: 0 rem(20px);
  @include mq(pc) {
    padding: 0 rem(40px);
  }
}
.inner-s {
  width: 100%;
  height: 100%;
  max-width: rem(1580px);
  padding: 0 rem(20px);
  margin: 0 auto;
  @include mq(pc) {
    padding: 0 rem(80px);
  }
}
.inner-sp {
  width: 100%;
  height: 100%;
  max-width: rem(814px);
  padding: 0 rem(20px);
  margin: 0 auto;
  @include mq(pc) {
    padding: 0 rem(40px);
  }
}
.inner-classroom {
  width: 100%;
  height: 100%;
  max-width: rem(953px);
  padding: 0 rem(20px);
  margin: 0 auto;
  @include mq(pc) {
    padding: 0 rem(40px);
  }
}
.inner-privacy {
  width: 100%;
  height: 100%;
  max-width: rem(1094px);
  padding: 0 rem(20px);
  margin: 0 auto;
  @include mq(pc) {
    padding: 0 rem(40px);
  }
}
