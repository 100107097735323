@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// recommend
// ================================================================
.recommend {
  width: 100%;
  height: 100%;
  margin-top: rem(118px);
  position: relative;
  @include mq(pc) {
    margin-top: rem(237px);
  }
  @include mq(sp-tb) {
    aspect-ratio: 1980/1034;
    height: auto;
  }
}
.recommend__background {
  content: "";
  position: absolute;
  width: 100%;
  height: rem(1034px);
  top: 0;
  z-index: -2;
  @include mq(sp-tb) {
    height: 100%;
  }
}
.recommend__background img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

// ================================================================
//  recommend_content
// ================================================================
.recommend__items {
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: rem(30px) rem(10px);
  @include mq(sp-tb) {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
}
.recommend__item {
  max-width: rem(600px);
  width: 100%;
  position: relative;
  transition: all 0.3s ease-out;
  margin: 0 auto;
  height: rem(300px);
  @include mq(sp-tb) {
    max-width: rem(404px);
    width: 30%;
    height: rem(440px);
    padding: auto;
    margin: 0;
  }
  @include mq(pc-m) {
    width: 28.4%;
  }
}
.recommend__item::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $color-white;
  opacity: 0.6;
  z-index: -1;
  transition: all 0.3s ease-out;
}
.recommend__item + .recommend__item {
  margin-top: rem(20px);
  @include mq(sp-tb) {
    margin: 0;
  }
}
.recommend__item-link {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: rem(30px);
  transition: all 0.3s ease-out;
  @include mq(sp-tb) {
    padding-top: rem(85.5px);
  }
}
.recommend__wrapper {
  width: 100%;
  height: auto;
}
.recommend__wrapper-eng {
  @include txt(23px, 500, $color-default-font, center);
  @include txt-option(1, 0px);
}
.recommend__wrapper-title {
  margin-top: rem(28px);
  @include txt(35px, 500, $color-default-font, center);
  @include txt-option(1, 0px);
  transition: all 0.3s ease-out;
  @include mq(pc-m) {
    font-size: 45px;
  }
}
.recommend__wrapper-text {
  margin: 0 auto;
  margin-top: rem(31px);
  max-width: rem(250px);
  @include txt(16px, 500, $color-default-font, center);
  @include txt-option(3.125, 0px);
  font-family: $font-family-YuGothic;
  @include mq(pc-m) {
    max-width: rem(294px);
  }
}
.recommend__wrapper-text span {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $color-gray;
}

@media (hover: hover) {
  .recommend__item:hover {
    @include mq(sp-tb) {
      height: rem(344.75px);
    }
    .recommend__item-link {
      opacity: 1;
      @include mq(sp-tb) {
        padding-top: rem(38.37px);
      }
    }
    .recommend__wrapper-title {
      color: $backcolor-green;
    }
    &::after {
      transform: scaleX(1.1);
      opacity: 1;
      @include mq(sp-tb) {
        transform: scaleX(1);
      }
    }
  }
}
@media (hover: none) {
  .recommend__item:active {
    @include mq(sp-tb) {
      height: rem(344.75px);
    }
    .recommend__item-link {
      opacity: 1;
      @include mq(sp-tb) {
        padding-top: rem(36.37px);
      }
    }
    .recommend__wrapper-title {
      color: $backcolor-green;
    }
    &::after {
      transform: scaleX(1.1);
      opacity: 1;
      @include mq(sp-tb) {
        transform: scaleX(1);
      }
    }
  }
}
