@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// section
// ================================================================
.section {
}
.section__title {
  @include txt(24px, 700, $color-default-font, left);
  @include txt-option(1, 6px);
  font-size: rem(24px);
  display: flex;
  align-items: center;
  position: relative;
  @include mq(pc) {
    font-size: rem(40px);
  }
}
.section__text {
  max-width: rem(595px);
  margin-top: rem(60px);
  @include txt(16px, 400, $color-default-font, left);
  @include txt-option(1.8, 2.4px);
  font-size: rem(16px);
}
