@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// hamburger-menu
// ================================================================
.header__drawer {
  width: rem(40px);
  height: rem(30px);
  position: absolute;
  top: rem(-20px);
  right: rem(20px);
  z-index: 25;
  @include mq(sp-tb) {
    display: none;
  }
}
.header__drawer-line {
  display: block;
  width: 100%;
  height: rem(3px);
  margin-left: auto;
  transition: all 0.5s;
  background-color: $color-white;
  @include mq(sp-tb) {
    display: none;
  }
}
.header__drawer-line:nth-of-type(2) {
  width: 100%;
}
.header__drawer-line:nth-of-type(3) {
  width: 100%;
}
.header__drawer-line + .header__drawer-line {
  margin-top: rem(12px);
}
.header__drawer.active {
  .header__drawer-line:nth-of-type(1) {
    transform: translateY(15px) rotate(-45deg);
  }
  .header__drawer-line:nth-of-type(2) {
    display: none;
  }
  .header__drawer-line:nth-of-type(3) {
    width: 100%;
    transform: rotate(45deg);
  }
}
.header__overlay {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -50px;
  left: 0;
  z-index: 5;
  background-color: $color-blue;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
  @include mq(sp-tb) {
    display: none;
  }
}
.header__overlay.active {
  opacity: 0.8;
  pointer-events: auto;
}
.drawer__menu {
  display: block;
  position: absolute;
  z-index: 20;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
}
.drawer__menu.active {
  opacity: 1;
  pointer-events: auto;
}
.drawer__nav {
  position: absolute;
  top: rem(-50px);
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  max-width: rem(350px);
  width: 100%;
  @include mq(sp-s) {
    max-width: rem(300px);
  }
}
.drawer__items {
}
.drawer__item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: rem(30px);
  border-bottom: 1px solid $color-gray;
}
.drawer__item a {
  @include txt(20px, 500, $color-default-font, center);
  @include txt-option(1, 0px);
}
.drawer__item + .drawer__item {
  margin-top: rem(15px);
}

// ================================================================
//  drawer__link
// ================================================================
.drawer__link {
  display: block;
  margin-top: rem(20px);
}
.drawer__link-item {
  display: flex;
  justify-content: space-between;
  height: rem(70px);
  @include mq(sp-s) {
    height: rem(70px);
  }
}
.drawer__link-item:nth-child(2) {
  justify-content: start;
  height: rem(70px);
}
.drawer__link-item + .drawer__link-item {
  margin-top: rem(10px);
}
.drawer__topic {
  width: calc(350px / 2 - 10px);
  @include mq(sp-s) {
    width: calc(300px / 2 - 10px);
  }
}
.drawer__topic a {
  @include txt(16px, 700, $color-white, center);
  @include txt-option(1.73, 0px);
  transition: all 0.3s ease-out;
}
.drawer__web {
  width: calc(350px / 2 - 10px);
  @include mq(sp-s) {
    width: calc(300px / 2 - 10px);
  }
}
.drawer__web a {
  @include txt(16px, 700, $color-white, center);
  @include txt-option(1.73, 0px);
  background-color: $backcolor-hex;
  transition: all 0.3s ease-out;
}
.drawer__link-wrapper {
  width: calc(350px / 2 - 10px);
  display: flex;
  flex-direction: column;
  margin-right: rem(20px);
  @include mq(sp-s) {
    width: calc(300px / 2 - 10px);
  }
}
.drawer__reserve {
  width: 100%;
  height: rem(30px);
}
.drawer__reserve a {
  @include txt(16px, 700, $color-white, center);
  @include txt-option(1.73, 0px);
  background-color: $backcolor-red;
  transition: all 0.3s ease-out;
}
.drawer__payment {
  width: 100%;
  height: rem(30px);
  margin-top: auto;
}
.drawer__payment a {
  @include txt(16px, 700, $color-white, center);
  @include txt-option(1.73, 0px);
  background-color: $backcolor-red;
  transition: all 0.3s ease-out;
}
.drawer__instagram {
  width: rem(36px);
  height: rem(36px);
}

@media (hover: hover) {
  .drawer__topic a:hover {
    background-color: $hover-green;
    opacity: 1;
  }
  .drawer__web a:hover {
    background-color: $hover-hex;
    opacity: 1;
  }
  .drawer__reserve a:hover {
    background-color: $hover-red;
    opacity: 1;
  }
  .drawer__payment a:hover {
    background-color: $hover-red;
    opacity: 1;
  }
}
@media (hover: none) {
  .drawer__topic a:active {
    background-color: $hover-green;
    opacity: 1;
  }
  .drawer__web a:active {
    background-color: $hover-hex;
    opacity: 1;
  }
  .drawer__reserve a:active {
    background-color: $hover-red;
    opacity: 1;
  }
  .drawer__payment a:active {
    background-color: $hover-red;
    opacity: 1;
  }
}
