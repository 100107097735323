@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// header
// ================================================================
.header {
  width: 100%;
  height: rem(100px);
  position: fixed;
  z-index: 10;
  background-color: $color-blue;
  font-family: $font-family-YuGothic;
  @include mq(sp-tb) {
    height: rem(149px);
  }
}
.header__inner {
  width: 100%;
  max-width: rem(1760px);
  height: rem(50px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: rem(50px);
  padding: 0 rem(20px);
  position: relative;
  @include mq(pc-m) {
    padding: 0 rem(20px);
  }
  @include mq(sp-tb) {
    height: rem(74px);
  }
}
.header__icon {
  display: block;
  width: rem(70px);
  position: absolute;
  top: rem(-40px);
  z-index: 5;
  @include mq(sp-tb) {
    display: none;
  }
}

// ================================================================
//  header__nav
// ================================================================
.header__nav {
  display: none;
  @include mq(sp-tb) {
    display: block;
    width: 100%;
    max-width: rem(795px);
    margin-left: 0;
  }
  @include mq(pc-w) {
    margin-left: rem(127px);
  }
}
.header__items {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__item {
  display: flex;
  justify-content: center;
  min-width: rem(100px);
  @include mq(pc-m) {
    min-width: rem(120px);
  }
}
.header__item:nth-child(1) {
  min-width: rem(32px);
  @include mq(pc-m) {
    min-width: rem(90px);
  }
}
.header__item a {
  @include txt(16px, 500, $color-default-font, center);
  @include txt-option(1.68, 0px);
  transition: all 0.3s ease-out;
}
@media (hover: hover) {
  .header__item a:hover {
    color: $hover-hex;
    opacity: 1;
  }
}
@media (hover: none) {
  .header__item a:active {
    color: $hover-hex;
    opacity: 1;
  }
}

// ================================================================
//  header__link
// ================================================================
.header__link {
  display: none;
  @include mq(sp-tb) {
    display: flex;
    justify-content: space-between;
    height: 100%;
    max-width: rem(406px);
    min-width: rem(406px);
    margin-left: rem(10px);
  }
  @include mq(pc-m) {
    margin-left: rem(20px);
    max-width: rem(495px);
    min-width: rem(495px);
    justify-content: start;
  }
  @include mq(pc-w) {
    margin-left: rem(51px);
  }
}
.header__topic {
  width: rem(100px);
  @include mq(pc-m) {
    width: rem(132px);
  }
}
.header__topic a {
  @include txt(16px, 700, $color-white, center);
  @include txt-option(1.56, 0px);
  transition: all 0.3s ease-out;
}
.header__web {
  width: rem(100px);
  @include mq(pc-m) {
    width: rem(132px);
    margin-left: rem(13px);
  }
}
.header__web a {
  @include txt(16px, 700, $color-white, center);
  @include txt-option(1.56, 0px);
  background-color: $backcolor-hex;
  transition: all 0.3s ease-out;
}
.header__wrapper {
  width: rem(150px);
  height: inherit;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  @include mq(pc-m) {
    margin-left: rem(13px);
  }
}
.header__reserve {
  width: 100%;
  height: rem(33px);
}
.header__reserve a {
  @include txt(16px, 700, $color-white, center);
  @include txt-option(1.56, 0px);
  background-color: $backcolor-red;
  transition: all 0.3s ease-out;
}
.header__payment {
  width: 100%;
  height: rem(33px);
  margin-top: auto;
}
.header__payment a {
  @include txt(16px, 700, $color-white, center);
  @include txt-option(1.56, 0px);
  background-color: $backcolor-red;
  transition: all 0.3s ease-out;
}
.header__instagram {
  width: rem(36px);
  height: rem(36px);
  @include mq(pc-m) {
    margin-left: auto;
  }
}

@media (hover: hover) {
  .header__topic a:hover {
    background-color: $hover-green;
    opacity: 1;
  }
  .header__web a:hover {
    background-color: $hover-hex;
    opacity: 1;
  }
  .header__reserve a:hover {
    background-color: $hover-red;
    opacity: 1;
  }
  .header__payment a:hover {
    background-color: $hover-red;
    opacity: 1;
  }
}
@media (hover: none) {
  .header__topic a:active {
    background-color: $hover-green;
    opacity: 1;
  }
  .header__web a:active {
    background-color: $hover-hex;
    opacity: 1;
  }
  .header__reserve a:active {
    background-color: $hover-red;
    opacity: 1;
  }
  .header__payment a:active {
    background-color: $hover-red;
    opacity: 1;
  }
}
