@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// top-fv
// ================================================================
.top-fv {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.top-fv__content {
  position: absolute;
  display: flex;
  top: rem(129px);
  left: toVw(191);
  z-index: 5;
  @include mq(sp-s) {
    top: rem(110px);
    left: toVw(100);
  }
  @include mq(sp-tb) {
    top: rem(179px);
  }
}
.top-fv__icon {
  display: none;
  width: max(toVw(246.1), 65px);
  height: toVw(268.8);
  top: rem(129px);
  left: toVw(191);
  @include mq(sp-s) {
    top: rem(110px);
    left: toVw(100);
  }
  @include mq(sp-tb) {
    display: block;
    top: rem(179px);
  }
}
.top-fv__title {
  margin-left: rem(14.9px);
  padding-top: toVw(97);
  @include txt(16px, 500, $color-blue-font, center);
  @include txt-option(1, 0px);
  font-family: $font-family-YuGothic;
  @include mq(sp-s) {
    padding-top: 22px;
    margin-left: rem(10px);
  }
  @include mq(sp-m) {
    font-size: 20px;
  }
  @include mq(tb) {
    font-size: 24px;
  }
  @include mq(tb-pc) {
    font-size: 24px;
  }
  @include mq(pc-s) {
    font-size: 28px;
  }
}
