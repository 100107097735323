@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// topic
// ================================================================
.topic {
  width: 100%;
  margin-top: rem(105px);
  @include mq(pc) {
    margin-top: rem(210px);
  }
}
.topic__area {
  width: 100%;
  height: 100%;
  padding: 0 rem(20px);
  padding-top: rem(37px);
  padding-bottom: rem(45px);
  background-color: $color-blue;
  @include mq(pc-m) {
    padding-left: rem(40px);
    padding-right: rem(40px);
  }
}
.topic__title {
  @include txt(30px, 500, $color-default-font, center);
  @include txt-option(1, 0px);
}
.topic__text {
  @include txt(16px, 500, $color-default-font, center);
  @include txt-option(1, 0px);
  margin-top: rem(12px);
  font-family: $font-family-YuGothic;
}
.topic__content {
  width: 100%;
  height: 100%;
  margin-top: rem(38px);
}
.topic__items {
  display: block;
  width: 100%;
  height: 100%;
  @include mq(sp-tb) {
    display: flex;
    justify-content: space-between;
  }
}
.topic__item {
  position: relative;
  max-width: rem(600px);
  margin: 0 auto;
  @include mq(sp-tb) {
    width: 21.2%;
    max-width: rem(285px);
  }
}
.topic__item-line {
  display: none;
  @include mq(sp-tb) {
    display: block;
    border-right: 1px solid $color-gray;
  }
}
.topic__item-line + .topic__item {
  margin-top: rem(40px);
  @include mq(sp-tb) {
    margin-top: 0;
  }
}
.topic__item-link {
  width: 100%;
}
.topic__item-img {
  width: 100%;
}
.topic__item-title {
  @include txt(20px, 500, $color-default-font, left);
  @include txt-option(1.7, 0px);
  font-family: $font-family-YuGothic;
}
.topic__item-title h3 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.topic__item-date {
  @include txt(16px, 500, $color-default-font, left);
  @include txt-option(2.18, 0px);
  font-family: $font-family-YuGothic;
}
