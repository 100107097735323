@charset "UTF-8";
@use "../../global" as *;

// ================================================================
//  classroom_fv
// ================================================================
.classroom__fv {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.classroom__fv__img {
  margin-top: rem(100px);
  width: 100%;
  height: auto;
  aspect-ratio: 767/900;
  @include mq(sp-tb) {
    margin-top: rem(149px);
  }
  @include mq(pc) {
    aspect-ratio: 1920/900;
  }
}
.classroom__fv__img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.classroom__fv__title {
  position: absolute;
  top: rem(132px);
  left: toVw(120);
  z-index: 5;
  @include txt(28px, 500, $color-default-font, left);
  @include txt-option(1.43, 0px);
  @include mq(sp-s) {
    top: rem(112px);
    font-size: 20px;
  }
  @include mq(sp-tb) {
    top: rem(181px);
    font-size: 32px;
  }
  @include mq(pc-m) {
    font-size: 37px;
  }
}
