@charset "UTF-8";
// ================================================================
// カラー変数
// ================================================================
$color-default-font: #000000;
$color-base: #fcfcfc;
$color-blue-font: #002f85;

$color-black: #231815;
$color-blacksub: #2b2e34;
$color-white: #fff;
$color-green: #37c7c1;
$color-blue: #70f5f0;
$color-gray: #707070;

//仮の色
$backcolor-blue: #d8fcfb;
$backcolor-green: #1b8b86;
$backcolor-red: #e6666b;
$backcolor-hex: #41b954;

//ホバー時
$hover-blue: #4db1ac;
$hover-green: #1b6965;
$hover-red: #c93535;
$hover-hex: #1b8b86;
$hover-btn: #2c8b49;
