@charset "UTF-8";

// ================================================================
// フォント変数
// ================================================================
$font-family-base: source-han-sans-japanese, sans-serif;
$font-family-YuMincho: "游明朝", "Yu Mincho", YuMincho, serif;
$font-family-YuGothic: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
$font-family-Helvetica: Helvetica, "Helvetica Neue", serif;
$font-family-Hiragino: "Hiragino Kaku Gothic ProN", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", serif;
