@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// swiper
// ================================================================
.top-fv__content-container {
  margin-top: rem(100px);
  @include mq(sp-tb) {
    margin-top: rem(149px);
  }
}
.swiper {
  overflow: visible;
  padding: 0;
}
.swiper-wrapper {
  width: 100%;
  height: auto;
}
.swiper-slide {
  width: 100%;
  height: auto;
  aspect-ratio: 767/1002;
  @include mq(pc) {
    aspect-ratio: 1736/1002;
  }
}
.swiper-slide img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: rem(22px);
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 toVw(10);
}
.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-pagination-bullet {
  display: block;
  width: rem(13px);
  height: rem(13px);
  background-color: $color-white;
  opacity: 1;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: $color-blue;
}
