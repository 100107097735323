@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// calendar
// ================================================================
.calendar {
  padding-top: rem(50px);
  padding-bottom: rem(50px);
  position: relative;
}

.calendar__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-default-font;
  opacity: 0.03;
  z-index: -1;
}
.calendar__inner {
  width: 100%;
  height: 100%;
  max-width: rem(680px);
  padding: 0 rem(20px);
  margin: 0 auto;
  @include mq(sp-tb) {
    max-width: rem(1031px);
  }
  @include mq(pc) {
    padding: 0 rem(40px);
  }
}
.calendar__content {
  max-width: rem(951px);
  max-height: rem(901px);
  aspect-ratio: 951 / 901;
  background-color: $color-white;
}
.calendar__content iframe {
  display: block;
  border: none;
  width: 100%;
  height: 100%;
  overflow: auto;
}
