@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// guid
// ================================================================
.guid {
  width: 100%;
  background-color: $color-blue;
  padding-top: rem(117px);
  padding-bottom: rem(129px);
}
.guid__content {
  width: 100%;
}
.guid__items {
  display: block;
  @include mq(sp-tb) {
    display: flex;
    justify-content: space-between;
  }
}
.guid__item {
  max-width: rem(600px);
  width: 100%;
  margin: 0 auto;
  padding-bottom: rem(33.5px);
  background-color: $color-green;
  transition: 0.3s all ease-out;
  cursor: pointer;
  @include mq(sp-tb) {
    max-width: rem(672px);
    width: 47.3%;
    margin: 0;
  }
}
.guid__item + .guid__item {
  margin-top: rem(40px);
  @include mq(sp-tb) {
    margin-top: 0;
  }
}
.guid__item-link {
  width: 100%;
  height: 100%;
}
.guid__item-img {
  width: 100%;
  overflow: hidden;
}
.guid__item-img img {
  transition: 0.3s all ease-out;
}
.guid__item-title {
  @include txt(25px, 700, $color-white, center);
  @include txt-option(1, 0px);
  margin-top: rem(34px);
  font-family: $font-family-Helvetica;
}
.guid__item-text {
  @include txt(16px, 700, $color-white, center);
  @include txt-option(1.687, 0px);
  margin-top: rem(15.5px);
  font-family: $font-family-YuGothic;
  padding: 0 rem(20px);
}
@media (hover: hover) {
  .guid__item:hover {
    background-color: $hover-blue;
    .guid__item-link {
      opacity: 1;
    }
    .guid__item-img img {
      transform: scale(1.1, 1.1);
    }
  }
}
@media (hover: none) {
  .guid__item:active {
    background-color: $hover-blue;
    .guid__item-link {
      opacity: 1;
    }
    .guid__item-img img {
      transform: scale(1.1, 1.1);
    }
  }
}
