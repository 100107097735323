@charset "UTF-8";
@use "../../global" as *;

// ================================================================
// fv
// ================================================================
.fv {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.fv__img {
  margin-top: rem(100px);
  width: 100%;
  height: auto;
  aspect-ratio: 767/900;
  @include mq(sp-tb) {
    margin-top: rem(149px);
  }
  @include mq(pc) {
    aspect-ratio: 1920/900;
  }
}
.fv__img img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
